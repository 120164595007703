jQuery(document).ready(function ($) {
    $('.info-popup .close').click(function (e) {
        e.preventDefault();
        $(this).closest('.info-popup').removeClass('show');
        $.ajax({
            url: ajax_url,
            type: 'post',
            data: {
                action: 'popup_set_cookie',
                continue: $(this).attr('href'),
            },
            success: function (response) {
                if (response.success && response.redirect) {
                    window.location.href = response.redirect;
                }
            }

        });
    });
});